<template>
  <div>
    <article class="page order">
      <div class="content-con">
        <h2 class="page-tit"><em>订单资讯</em><span>订单详细资讯</span></h2>
        <section class="order-detial">
          <dl>
            <dt>订单编号</dt>
            <dd>{{ orderData.OrderId }}</dd>
            <dt>收件人</dt>
            <dd>{{ orderData.Buyer.Name }}</dd>
            <dt>收件人手机号</dt>
            <dd>{{ orderData.Buyer.Mobile }}</dd>
            <dt>收件人地址</dt>
            <dd>{{ orderData.Receiver.Address }}</dd>
            <dt>总金额</dt>
            <dd>{{ orderData.TotalPrice }}</dd>
            <!--<dt>付款币别</dt>
            <dd>{{ PaymentType[orderData.PaymentType] }}</dd>-->
            <dt>订单备注</dt>
            <dd>{{ orderData.Receiver.Memo }}</dd>
            <dt>付款状态</dt>
            <dd>
              <span class="status-processing" v-if="orderData.PayStatus == 0"
                >未付款</span
              >
              /
              <a
                :href="orderData.PaymentInfo"
                target="_blank"
                v-if="orderData.PayStatus == 0"
                >二维码付款去</a
              >
              <span class="status-processing" v-if="orderData.PayStatus == 1"
                >已付款</span
              >
            </dd>
            <dt>物流状态</dt>
            <dd v-if="orderData.ShipStatus == 0">
              {{ ShipStatus[orderData.ShipStatus] }}
            </dd>
            <dd v-if="orderData.ShipStatus == 1">
              {{ ShipStatus[orderData.ShipStatus] }} - {{ orderData.ShipInfo }}
            </dd>
          </dl>
        </section>
        <section class="order-action">
          <a href="index" class="btn-action">继续购物</a>
          <a href="inquire" class="btn-active">查看订单列表</a>
        </section>
      </div>
    </article>
  </div>
</template>

<script>
import { GetOrderByOrderId } from "@/service/api";
import router from "../router";

export default {
  name: "Order",
  data() {
    return {
      phoneNo: "",
      orderData: "",
      PaymentType: ["CC", "ATM", "MMK", "CGP", "USDT", "BTC", "ETH"],
      OrderStatus: ["处理中", "已完成", "已退款", "已建立"],
      PayStatus: ["未付款", "已付款"],
      ShipStatus: ["未出货", "已出货"],
    };
  },
  mounted: function () {
    this.$parent.SetWindowTitle("订单查询结果");
    GetOrderByOrderId(this.$route.query.phoneNo, this.$route.query.orderId)
      .then((resp) => {
        this.orderData = resp.data;
      })
      .catch((data) => {
        if (data.status === 401) alert("Error: token is not found;");
        if (data.status === 403) alert("Error: authentication failed;");
      });
    this.phoneNo = this.$route.query.phoneNo;
    $(".banner-cover").addClass("_lvThr");
  },
  methods: {
    GetImageUrl: function (imgId, size) {
      var d = new Date();
      var n = d.getTime();
      return (
        "https://n2imageservice.azurewebsites.net/api/images/shopmall/" +
        imgId +
        "/" +
        size +
        "/0?" +
        n
      );
    },
    formatDate: function (value) {
      if (value == null) return "";
      return value.replace("T", " ");
    },
    OpenFancyBox(url) {
      $.fancybox.open({ src: url, type: "iframe" });
      $(document).on("afterClose.fb", function (e, instance, slide) {
        location.replace(location);
      });
    },
    ShipVender(str) {
      return str.split("-")[0];
    },
    OpenLogistics(orderId, phoneNo) {
      return (
        "window.open('PopUp-Logistics?orderId=" +
        orderId +
        "&phoneNo=" +
        phoneNo +
        "','LogisticsInfo',config='height=400,width=550,toolbar=no')"
      );
    },
    OpenRecipient(orderId, phoneNo) {
      return (
        "window.open('PopUp-Recipient?orderId=" +
        orderId +
        "&phoneNo=" +
        phoneNo +
        "','RecipientInfo',config='height=400,width=550,toolbar=no')"
      );
    },
    CollapseHref(id) {
      return "#" + id;
    },
  },
};
</script>
